<template>
  <el-dialog
    id="SelectTemplateDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="50%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="filterString = ''"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="selectTemplateTable"
          id="selectTemplateTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="templates"
          :gridOptions="gridOptions"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedTemplate">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="onCancel" class="cancel-button">{{ $t("Відмінити") }}</el-button>
      <el-button type="button" @click="onCommitCancel" class="cancel-button">{{ $t("Зкинути") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { _ } from "vue-underscore";
import { bus } from "@/main";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "SelectTemplate",
  props: ["busEvent"],
  components: { AgGridVue },
  data() {
    return {
      loading: false,
      filterString: "",
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      model: {},
      initModel: {},
      selectedTemplate: null,
      waitForAnswer: false,
      keyUpTimer: null,
      filterHasChanged: false,
      gridApi: null,
      gridColumnApi: null,
      excludeForSelect: [],
      operation: "",
      axiomaId: null,
      templateId: null,
      noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
      },

      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: [
        {
          headerName: $tt("Назва"),
          field: "name",
          flex: 1,
        },
        {
          headerName: $tt("Бонус"),
          field: "bonus",
          width: "120px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Товарів"),
          field: "_skuCount",
          width: "100px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Примітка"),
          field: "notes",
          flex: 1,
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || vm.title;
      this.filterString = "";
      this.templateId = data && data.initModel ? data.initModel.templateId : null;
      this.axiomaId = data && data.initModel ? data.initModel.axiomaId : null;
      this.operation = data && data.operation ? data.operation : "";
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
  },
  computed: {
    templates() {
      return this.$store.getters["templates/templatesStoreFiltered"](this.filterString);
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 72 - 48 - 16 - 48;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady() {
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("SelectTemplateGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    onCommit() {
      this.$emit("select-commit", { template: this.selectedTemplate ? this.selectedTemplate : null, axiomaId: this.axiomaId });
      //if (this.selectedTemplate) {
      //this.$emit("select-commit", { template: this.selectedTemplate?this.selectedTemplate:null, axiomaId: this.initModel.axiomaId });
      //          this.dialogShow = false;
      //}
      //else this.$message.error($tt("Будь ласка, оберіть хоча б один із рядків!"));
    },
    onCommitCancel() {
      this.$emit("select-commit", { template: null, axiomaId: this.axiomaId });
    },
    onCancel() {
      this.$emit("select-cancel");
      this.dialogShow = false;
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.loading = false;
      this.gridOptions.api.deselectAll();
      if (this.templateId) {
        var node = this.gridOptions.api.getRowNode(this.templateId);
        if (node) node.setSelected(true);
      }
    },
    onClose() {
      this.unobserveHeight();
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedTemplate = rows[0];
      } else {
        this.selectedTemplate = null;
      }
    },
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { SelectTemplateGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
  },
};
</script>

<style lang="scss" scope>
#SelectTemplateDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 6px !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#SelectTemplateDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 16px 16px 0 16px !important;
}

.flex_container {
  width: 100%;
}
</style>
